import React, { useEffect } from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../store/productSlice";
import Loader from "../Common/Loader";
import StatusCode from "../../Utils/StatusCode";
import ProductsCard from "../Cards/ProductsCard";
import { da } from "date-fns/locale";

const LatestProducts = () => {
  const { data, status } = useSelector((state) => state.products);
  const theme = useTheme();
  const isMobille = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <>
      {status === StatusCode.LOADING && <Loader />}
      <Box sx={{  p: 2, pb: 4, my: 3, mx: 0,  bgcolor:' #f6f7f8',          boxShadow:' rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' , borderRadius:'5px'}}>
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: {
              lg: "24px",
              xs: "16px",
              md: "24px",
              sm: "18px",
      
            },
                    fontWeight: 700,
          }}
        >
          Latest Products
        </Typography>
        <Box>
{
  isMobille ? (
    <>
    <Box className="hide_scrollbar" sx={{display:'flex', overflow:'scroll', flexGrow:'initial', columnGap:2, mt:3}}>
               {data === undefined ? (
              <p>No Product</p>
            ) : (
              data.slice(0, 10).map((product, index) => (
                <Box sx={{width:'200px', }} key={product._id}>
                  <Link to={`/product-view/${product._id}`} key={index}>
                    <ProductsCard
                      image={product.images && product.images[0]}
                      price={product.price.toLocaleString()}
                      flag={product?.country?.flagUrl}
                    />
                  </Link>
                </Box>
              ))
            )}
    </Box>
    </>
  ):(
    <>
              <Grid container spacing={2} sx={{ mt: 1 }}>
            {data === undefined ? (
              <p>No Data</p>
            ) : (
              data.slice(0, 10).map((product, index) => (
                <Grid item lg={2.4} md={3} sm={4} xs={6} key={product._id}>
                  <Link to={`/product-view/${product._id}`} key={index}>
                    <ProductsCard
                      image={product.images && product.images[0]}
                      price={product.price.toLocaleString()}
                      flag={product?.country?.flagUrl}
                    />
                  </Link>
                </Grid>
              ))
            )}
          </Grid>
    </>
  )
}
        </Box>
      </Box>
    </>
  );
};

export default LatestProducts;
