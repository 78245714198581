import { createTheme } from "@mui/material";

const Theme = createTheme({
  palette: {
    primary: {
      // main: "#2E1834",
      main: "#7B408C",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: "#5C3069",
        },
      },
    },
  },
  typography: {
    fontFamily: "IBM Plex Sans",
    h1: {
      fontFamily: "syne",
      fontWeight: 700,
      fontSize: "110px",
      color: "#fff",
      lineHeight: { lg: "98px", xs: "50px" },
    },
    h2: {
      fontFamily: "IBM Plex Sans",
      fontWeight: 300,
      color: "#fff",
      fontSize: "20px",
      lineHeight: "30px",
    },
    h3: {
      fontFamily: "syne",
      fontWeight: 700,
      color: "#333333",
      fontSize: "64px",
    },

    h4: {
      fontFamily: "IBM Plex Sans",
      fontWeight: 600,
      color: "#1a1a1a",
      fontSize: "20px",
    },
    h5: {
      fontFamily: "IBM Plex Sans",
      fontWeight: 400,
      color: "#1a1a1a",
      fontSize: "14px",
      textTransform: "uppercase",
    },

    subtitle1: {
      fontFamily: "IBM Plex Sans",
      color: "#151515",
      fontWeight: 600,
      fontSize: "16px",
      
      // lineHeight: "34px",
    },
    subtitle2: {
      fontFamily: "IBM Plex Sans",
      color: "#1a1a1a",
      fontWeight: 500,
      fontSize: "30px",
      // lineHeight:'34px'
    },

    body1: {
      fontFamily: "IBM Plex Sans",
      fontWeight: 300,
      fontSize: "12px",
      color: "#8B98A7",

    },
    body2: {
      color: "#151515",
      fontWeight: 500,
      fontSize: "12px",
    },
    caption: {
      fontFamily: "IBM Plex Sans",
      fontWeight: 400,
      fontSize: "12px",
      color: "#1a1a1a",
    },
    button: {
      fontFamily: "IBM Plex Sans",
      textTransform: "initial",
    },
  },
});

export default Theme;
