import React from "react";
import { Box, Avatar, Typography, Button } from "@mui/material";
import blogImg from "../../assets/blog.png";
import { Link } from "react-router-dom";

const BlogCard = ({ image, title, caption , link}) => {
  return (
    <>
      <Box sx={{ bgcolor: "#fff", p: 1 }}>
        <Avatar
          variant="square"
          sx={{ width: "100%", height: "250px" }}
          src={image}
        />
        <Box sx={{ mt: 1 }}>
          <Typography
            variant="caption"
            sx={{ fontSize: "16px", fontWeight: 700 }}
          >
            {title}
          </Typography>
          <br />
          <Box sx={{ maxHeight: "40px", overflow: "hidden" }}>
            <Typography
              variant="caption"
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                textOverflow: "ellipsis",
              }}
            >
              {caption}
            </Typography>
          </Box>
          <Link to={link}>
            <Button
              sx={{
                color: "#1a1a1a",
                textDecoration: "underline",
                fontWeight: 700,
              }}
            >
              Read more
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default BlogCard;
