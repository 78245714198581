import React from "react";
import Navbar from "../../Components/Common/Navbar";
import Footer from "../../Components/Common/Footer";
import Newsletter from "../../Components/Common/Newsletter";
import { Box, Typography } from "@mui/material";
import clock from "../../assets/Icons/clock.svg";
import img1 from "../../assets/newton.jpeg";

const SingleBlog = () => {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          margin: "0 auto",
          mt: { lg: 15, md: 12, sm: 8, xs: 10 },
          mb: 4,
          bgcolor: "#fff",
          p: 4,
          px:{md:4, xs:2},
          width: { md: "85%", xs: "95%" },
          boxSizing: "border-box",
        }}
      >
        <Typography
          sx={{ color: "#151515", fontWeight: 700, fontSize:{md:'40px', xs:'30px'},  mt: 3 }}
        >
          I discovered why at 33
        </Typography>
        <Box
          sx={{ mt: 2, display: "flex", columnGap: 2, alignItems: "center" }}
        >
          <img src={clock} />{" "}
          <Typography sx={{ fontWeight: 400, fontSize: "12px" }}>
            Febuary 12, 2021
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 4,
            height: {md:"600px", xs:'300px'},
            background: `url('${img1}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></Box>
        <Box sx={{ mt: 2 }}>
          <Typography sx={{ color: "#151515", fontWeight: 400 }}>
            By Newton Ray
            <br />
            <br />
            “The two most important days of a man’s life are the days on which
            he was born and the day on which he discovers why” - Mark Twain
            <br />
            <br />
            I embraced Software Engineering shortly after I woke up from a
            hunger-induced nap (another day's story!) with an idea to build an
            online platform that would change the course of social. That was on
            February 11, 2018 in my little studio apartment in Lagos, Nigeria. I
            was 30 already.
            <br />
            <br />
            As a journalist with Bachelor of Arts and Master of Arts degrees in
            English Language and Literary Studies that should naturally be a
            mere dream, a day dream, in fact, - usually less in hierarchy in the
            variation of meaningful dreams - in my opinion, of course. I told
            myself I was disillusioned; hunger was the apparent cause. I will
            get up, get something to eat and get on with my life.
            <br />
            <br />
            But it’s exactly three years today, the voice of that idea has
            continued to cry resoundingly for expression in my heart. It refused
            to be quiescent. When many were certain I was uncertain about my
            convictions, in plentiful times of confusion, abandonment, regrets,
            failures, tears and rejections, it billows re-assurances and echoes
            of hope.
            <br />
            <br />
            It started in June 2018 when I presented a prototype design of my
            idea to a Silicon Valley-based Venture Capitalist, a lady of storied
            exploits in technology, who had come to Lagos, Nigeria to talk to
            women about tech. To this day, I can’t tell what might have
            possessed her or what fascinated her about a less than average
            interface of Uvuru, but she looked at me straight in the eye and
            said, “You should not hire someone to build this for you. You should
            build this yourself.”
            <br />
            <br />
            “Me?”
            <br />
            “Build it myself?”
            <br />
            <br />I studied English Language. I understand they call it
            Programming Language. But these were walls apart. But that didn’t
            convince her. She took my number and email address. And that was the
            beginning of a mother and child relationship.
            <br />
            <br />
            By the end of 2018, I had gained an entry to a tuition-free Software
            Engineering School in Silicon Valley, California. And that was how I
            began my journey to Software. She was there all the way: to pick me
            from the airport, to drive me around Sans Francisco - she basically
            introduced me to the world of tech - Integration, Artificial
            Intelligence, community service and love for Africa - Nigeria in
            particular. She became my mentor.
            <br />
            <br />
            Today, I am studying Software Engineering in the United States -
            learning computer languages from C+, to Python, to Flutter(dart),
            adding one programming language after another. Last December, I
            completed a certification training as a Clinical Data Manager and
            Database Developer skilled in building and managing database CRFs
            for clinical research using Medidate Rave 5.6.4. Yet, I hear the
            echoes of that cry in my heart for more.
            <br />
            <br />
            With the continuous training programs, I also have recorded many
            challenging moments especially living in a foreign land toppled by
            the recent and rude intrusion of Coronavirus into our personal and
            corporate destinies. But when you find your “thing” no one or
            “nothing” takes it from you. With every obstacle has always followed
            the opportunity for adjustment and adaptation. <br />
            <br />
            After nearly a year of building my first software application and
            website, with robust collaborative efforts of three young Nigerian
            developers and friends in the US and India, Mamaket - a solution for
            Nigeria and Africa - was born. The beta version of the solution was
            launched on Google Playstore On January 4th 2021.
            <br />
            <br />
            Although there is so much work ahead of me, I can finally say that
            the journey has just begun. I discovered why at 33. You too can.
          </Typography>
        </Box>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
};

export default SingleBlog;
