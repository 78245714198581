import { CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
  Typography,
  Modal,
  Autocomplete,
  InputAdornment,
  LinearProgress,
} from "@mui/material";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import successIcon from "../../assets/Icons/sell/5.svg";
import { AiOutlineFileImage } from "react-icons/ai";

import React, { act, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ImImages } from "react-icons/im";
import { HiViewfinderCircle } from "react-icons/hi2";
import { RiDeleteBin2Line } from "react-icons/ri";
import ConfettiExplosion from "react-confetti-explosion";
import {
  GET_CATEGORIES_N_SUB,
  GET_PRODUCT_ORIGIN,
  GET_PROFILE,
} from "../../Utils/Queries";
import { GET_COUNTRIES, GET_STATES, GET_CITY } from "../../Utils/Queries";
import UploadComponent from "./UploadComponent";
import {
  CREATE_PRODUCT,
  DELETE_EXISTING_IMAGE,
  EDIT_PRODUCT,
  EDIT_PRODUCT_IMAGE,
} from "../../Utils/Mutations";
import Loader from "../../Components/Common/Loader";
import { useSnackbar } from "notistack";
import ProductsCard from "../../Components/Cards/ProductsCard";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const EditProduct = ({ open, onClose, data, refetch }) => {
  const { enqueueSnackbar } = useSnackbar();

  const country_id = "65fe9e4dd5a2f2d3f3021a0b";
  const [activeStep, setActiveStep] = useState(1);

  const [uploadedImages, setUploadedImages] = useState(null);

  const [openModal, setOpenModal] = React.useState(false);

  const [isExploding, setIsExploding] = React.useState(false);

  const [productDetails, setProductDetails] = useState({
    category: "",
    title: "",
    description: "",
    state: "",
    city: "",
    price: "",
    quantity: "",
    negotiable: false,
  });

  const updateUploadedImages = (images) => {
    setUploadedImages(images);
  };

  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const [selectedPreviewImage, setSelectedPreviewImage] = useState(null);

  useEffect(() => {
    if (uploadedImages) {
      setSelectedPreviewImage(uploadedImages[0]);
    }
  }, [uploadedImages]);

  const handleOpen = () => {
    setIsExploding(true);
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
    setIsExploding(false);
  };
  useEffect(() => {
    getState({
      variables: {
        country: country_id,
      },
    });
    getCity({
      variables: {
        state: productDetails?.state?._id,
      },
    });
  }, [productDetails?.state]);

  const { loading: loadingCategories, data: categories, error } = useQuery(
    GET_CATEGORIES_N_SUB
  );
  const { loading: loadingOrigins, data: origins } = useQuery(
    GET_PRODUCT_ORIGIN
  );

  const [
    getCity,
    { loading: cityLoad, error: cityError, data: cityData },
  ] = useLazyQuery(GET_CITY);

  const [
    getState,
    { loading: stateLoad, error: statError, data: stateData },
  ] = useLazyQuery(GET_STATES);

  const [productOrigin, setProductOrigin] = useState(null);

  const optionsList = origins?.getProductOrigins?.items || [];
  // console.log(optionsList)

  const {
    category,
    title,
    description,
    state,
    city,
    price,
    quantity,
    negotiable,
  } = productDetails;

  const [isLoading, setIsLoading] = useState(false);

  const [editProduct] = useMutation(EDIT_PRODUCT);

  const handleEditProduct = async () => {
    setIsLoading(true);

    try {
      await editProduct({
        variables: {
          payload: {
            productId: data?._id,
            name: title,
            description: description,
            isNegotiable: negotiable,
            price: parseFloat(price),
            categories: category?._id,
            country: productOrigin?._id,
            productState: state?._id,
          },
        },
      });
      handleOpen();
      // console.log(data);
      setIsLoading(false);
      refetch();
      onClose();
    } catch (error) {
      setIsLoading(false);
      // console.error("Error submitting dispute:", error);
      handleAlert(`${error.message}`, "error");
    }
  };

  // console.log(data);

  const [selectedImage, setSelectedImage] = useState(null);
  useEffect(() => {
    if (data) {
      setProductDetails({
        ...productDetails,
        description: data?.description,
        title: data.name,
        category: data?.categories[0],
        price: data?.price,
        quantity: data?.quantityInStock,
        negotiable: data?.isNegotiable,
      });
      setProductOrigin();
      setExistingImages(data?.images);
    }
  }, [data]);

  // console.log(category, "Category");

  // ////////////  EVERYTHING IMAGES

  const [existingImages, setExistingImages] = useState([]);
  const [images, setImages] = useState([]);
  const generateId = () => {
    return Date.now() + Math.random().toString(36).substr(2, 9);
  };

  const onDrop = (acceptedFiles) => {
    setImages((prevImages) => {
      // If the number of previously uploaded images + new files exceeds 4, limit the upload
      const totalImages = prevImages.length + acceptedFiles.length;
      if (totalImages > 4 - existingImages?.length) {
        const remainingSlots = 4 - existingImages?.length - prevImages.length;
        const limitedFiles = acceptedFiles.slice(0, remainingSlots);

        const imageFiles = limitedFiles.map((file) =>
          Object.assign(file, {
            id: generateId(), // Assign a unique ID to each file
            preview: URL.createObjectURL(file), // Generate a preview URL for the image
          })
        );
        return [...prevImages, ...imageFiles];
      }

      // If the total is less than or equal to 4, allow all the files
      const imageFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          id: generateId(), // Assign a unique ID to each file
          preview: URL.createObjectURL(file), // Generate a preview URL for the image
        })
      );
      return [...prevImages, ...imageFiles];
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [],
      "image/jpeg": [], // Accept .jpg and .jpeg
      "image/jpg": [],
      "image/svg+xml": [], // Accept .svg files
    },
  });
  const deleteImage = (idToDelete) => {
    setImages((prevImages) =>
      prevImages.filter((image) => image.id !== idToDelete)
    );
  };

  const [editProductImage] = useMutation(EDIT_PRODUCT_IMAGE);

  const [deleteExistImage] = useMutation(DELETE_EXISTING_IMAGE);

  const [existSelected, setExistSelected] = useState(null);

  const handleEditProductImage = async () => {
    setIsLoading(true);

    await editProductImage({
      variables: {
        files: images,
        productId: data?._id,
      },
    })
      .then((res) => {
        handleAlert(`${res?.data?.addMoreImagesToProduct?.message}`, "success");
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        handleAlert(`${error?.message}`, "error");
        setIsLoading(false);
      });
  };

  const handleDeleteExistingImage = async () => {
    setIsLoading(true);
    const newImages = existingImages.filter((image) => image !== existSelected);
    // console.log(newImages, existingImages)

    await deleteExistImage({
      variables: {
        productId: data?._id,
        images: [existSelected],
      },
    })
      .then((res) => {
        handleAlert(
          `${res?.data?.removeImagesFromProduct?.message}`,
          "success"
        );
        setIsLoading(false);
        setExistingImages(newImages);
      })
      .catch((err) => {
        // console.log(err);
        handleAlert(`${error?.message}`, "error");
        setIsLoading(false);
      });
  };

  const {
    data: profile,
    error: profileErrror,
    loading: profileLoading,
  } = useQuery(GET_PROFILE);

  const { addresses } = profile?.getProfile?.profile || [];

  const [defaultAddress, setDefaultAddress] = useState([]);

  useEffect(() => {
    if (addresses) {
      const newAddress = addresses?.filter((item) => item?.isDefault === true);

      if (newAddress?.length > 0) {
        setDefaultAddress(newAddress[0]);

        // setProductDetails((){
        //   ...productDetails,
        //   state: { _id: newAddress[0]?.state },
        //   city:{_id: newAddress[0]?.city}
        // });

        setProductDetails((prevDetails) => ({
          ...prevDetails,
          state: { _id: newAddress[0]?.state },
          city: { _id: newAddress[0]?.city },
        }));
      }
    }
  }, [profile, addresses]);

  return (
    <>
      <Drawer open={open} onClose={onClose} anchor="right">
        {isLoading && <Loader />}
        <Box
          sx={{
            width: { lg: "50vw", md: "50vw", sm: "80vw", xs: "95vw" },
            p: 5,
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: { lg: "20px", md: "20px", sm: "16px", xs: "16px" },
                color: "#151515",
              }}
            >
              {activeStep === 1 ? "Edit Product" : "Review Product"}
            </Typography>
            <IconButton onClick={onClose} sx={{ color: "#151515" }}>
              <CloseOutlined />
            </IconButton>
          </Box>

          {activeStep === 1 && (
            <>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" sx={{ fontSize: "14px" }}>
                  Upload Images
                </Typography>
                <Typography sx={{ mt: 0.4 }}>
                  First picture-is the title picture. Drag to order <br />
                  (maximum of 4 photos, Pictures may not exceed <b>5MB</b>)
                </Typography>
              </Box>

              <Box sx={{ mt: 2 }}>
                <>
                  <Box
                    sx={{
                      bgcolor: "#fff",
                      height: "150px",

                      borderRadius: "10px",
                      mt: 1,
                      display: "flex",
                      alignItems: "center",
                      overflowX: "scroll",
                      overflowY: "hidden",

                      boxSizing: "border-box",
                      columnGap: 2,

                      "&::-webkit-scrollbar": {
                        borderRadius: "50px",
                        height: "5px",
                        // Set width of the scrollbar
                      },

                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#EBDCEF", // Style the thumb (scrollbar handle)
                        borderRadius: "50px",
                        width: "5px",
                        // Round the corners of the thumb
                      },
                    }}
                  >
                    <Box
                      sx={{
                        border: "0.5px dashed #C296CF",
                        boxSizing: "border-box",
                        width: "110px",
                        p: 1,
                        borderRadius: "8px",
                        height: "130px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        flexShrink: 0,
                      }}
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <ImImages style={{ color: "#AE73BF" }} />
                      <Typography sx={{ fontSize: "8px", mt: 0.5 }}>
                        Add More Images..
                      </Typography>
                    </Box>
                    {existingImages?.map((image, index) => (
                      <Box
                        key={index}
                        onClick={() => setExistSelected(image)}
                        sx={{
                          border: "1px solid #EBEAED",
                          boxSizing: "border-box",
                          width: "110px",
                          p: 1,
                          borderRadius: "8px",
                          height: "130px",
                          display: "grid",
                          placeContent: "center",
                          transition: "0.1s all linear",
                          cursor: "pointer",
                          // opacity: 0.5,
                          ...(existSelected === image && {
                            border: "1.5px solid #7B408C",
                          }),
                        }}
                      >
                        <Box
                          sx={{
                            background: `url('${image}')`,
                            width: "100px",
                            height: "120px",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#FF3347",
                              fontSize: "8px",
                              fontWeight: 500,
                              bgcolor: "#fff",
                              width: "fit-content",
                              p: 0.2,
                            }}
                          >
                            Existing
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                    {images?.map((image, index) => (
                      <Box
                        key={index}
                        onClick={() => setSelectedImage(image)}
                        sx={{
                          border: "1px solid #EBEAED",
                          boxSizing: "border-box",
                          width: "110px",
                          p: 1,
                          borderRadius: "8px",
                          height: "130px",
                          display: "grid",
                          placeContent: "center",
                          transition: "0.1s all linear",
                          cursor: "pointer",
                          ...(selectedImage === image && {
                            border: "1.5px solid #7B408C",
                          }),
                        }}
                      >
                        <Box
                          sx={{
                            background: `url('${image?.preview}')`,
                            width: "100px",
                            height: "120px",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#178c78",
                              fontSize: "8px",
                              fontWeight: 500,
                              bgcolor: "#fff",
                              width: "fit-content",
                              p: 0.2,
                            }}
                          >
                            New
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: {
                        lg: "row",
                        md: "row",
                        sm: "column",
                        xs: "column",
                      },
                      rowGap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        border: "1px solid #EBEAED",
                        mt: 2,
                        width: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        //               p:1,
                        borderRadius: "5px",
                        boxSizing: "border-box",
                      }}
                    >
                      {/* <IconButton
                        disabled={selectedImage === null}
                        onClick={() => {
                          // handleOpen();
                        }}
                      >
                        <HiViewfinderCircle style={{ fontSize: "16px" }} />
                      </IconButton>{" "} */}

                      <IconButton
                        disabled={selectedImage === null}
                        onClick={() => deleteImage(selectedImage?.id)}
                      >
                        <RiDeleteBin2Line
                          style={{ fontSize: "16px", color: "#FF3347" }}
                        />
                      </IconButton>
                    </Box>

                    <Box>
                      <Button
                        variant="contained"
                        sx={{ fontSize: "10px" }}
                        disabled={!images || images.length === 0}
                        onClick={handleEditProductImage}
                      >
                        Update more images
                      </Button>
                      <Button
                        onClick={handleDeleteExistingImage}
                        disabled={!existSelected}
                        variant="contained"
                        sx={{ fontSize: "10px", ml: 3, bgcolor: "#FF3347" }}
                      >
                        Delete Existing Image
                      </Button>
                    </Box>
                  </Box>

                  {/* <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <img src={selectedImage?.preview} width={"60%"} />
                        <Box sx={{ width: "40%", flex: 1 }}>
                          <Box align="center">
                            <IconButton
                              onClick={handleClose}
                              sx={{
                                bgcolor: "#fff",
                                mb: 2,
                                width: "70px",
                                height: "70px",
                              }}
                            >
                              <CloseOutlined />
                            </IconButton>
                          </Box>
                          <ProductsCard
                            image={selectedImage?.preview}
                            price={"00.00"}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Modal> */}
                </>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="filled"
                        label="Category"
                        fullWidth
                        value={productDetails.category?.name}
                        size="large"
                        margin="dense"
                        select
                      >
                        {categories?.fetchAllCategoriesAndTheirSubCategories?.map(
                          (cat, index) => (
                            <MenuItem
                              key={index}
                              onClick={(e) =>
                                setProductDetails({
                                  ...productDetails,
                                  category: cat.category,
                                })
                              }
                              value={cat?.category?.name}
                              sx={{
                                color: "#151515",
                                fontWeight: 400,
                                bgcolor: "#fff",
                              }}
                            >
                              {cat?.category?.name}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </Box>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Box>
                      <Autocomplete
                        value={productOrigin}
                        onChange={(event, newValue) => {
                          setProductOrigin(newValue);
                        }}
                        variant="filled"
                        options={origins?.getProductOrigins?.items}
                        getOptionLabel={(option) => option.name}
                        fullWidth
                        loading={loadingOrigins}
                        size="large"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            label=" Product Origin"
                            margin="dense"
                            placeholder="Select country"
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="filled"
                        label="Title"
                        fullWidth
                        margin="dense"
                        value={productDetails.title}
                        onChange={(e) =>
                          setProductDetails({
                            ...productDetails,
                            title: e.target.value,
                          })
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="filled"
                        label="Description"
                        fullWidth
                        multiline
                        rows={4}
                        margin="dense"
                        value={productDetails.description}
                        onChange={(e) =>
                          setProductDetails({
                            ...productDetails,
                            description: e.target.value,
                          })
                        }
                      />
                    </Box>
                  </Grid>
                  {/* <Grid item md={6} sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="filled"
                        label="State"
                        fullWidth
                        size="large"
                        margin="dense"
                        select
                        value={productDetails.state}
                        onChange={(e) =>
                          setProductDetails({
                            ...productDetails,
                            state: e.target.value,
                          })
                        }
                      >
                        {stateData?.fetchStates?.items?.map((item) => (
                          <MenuItem
                            value={item}
                            sx={{
                              color: "#151515",
                              fontWeight: 400,
                              bgcolor: "#fff",
                            }}
                          >
                            {item?.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </Grid>{" "}
                  <Grid item md={6} sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="filled"
                        label="City"
                        fullWidth
                        size="large"
                        margin="dense"
                        value={productDetails.city}
                        onChange={(e) =>
                          setProductDetails({
                            ...productDetails,
                            city: e.target.value,
                          })
                        }
                        select
                      >
                        {cityLoad ? (
                          <LinearProgress />
                        ) : (
                          cityData?.fetchCities?.items?.map((item) => (
                            <MenuItem
                              value={item}
                              sx={{
                                color: "#151515",
                                fontWeight: 400,
                                bgcolor: "#fff",
                              }}
                            >
                              {item?.name}
                            </MenuItem>
                          ))
                        )}
                      </TextField>
                    </Box>
                  </Grid> */}
                  <Grid item md={6} sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="filled"
                        label="Price"
                        fullWidth
                        size="large"
                        type="number"
                        margin="dense"
                        value={productDetails.price}
                        onChange={(e) =>
                          setProductDetails({
                            ...productDetails,
                            price: e.target.value,
                          })
                        }
                        InputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="filled"
                        label="Stock Quantity"
                        placeholder="Stock Quantity"
                        fullWidth
                        size="large"
                        margin="dense"
                        type="number"
                        value={productDetails.quantity}
                        onChange={(e) =>
                          setProductDetails({
                            ...productDetails,
                            quantity: e.target.value,
                          })
                        }
                        inputMode="numeric"
                        pattern="[0-9]*"
                      />
                    </Box>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <Switch
                        checked={productDetails.negotiable}
                        onChange={(e) =>
                          setProductDetails({
                            ...productDetails,
                            negotiable: e.target.checked,
                          })
                        }
                      />
                      <Typography variant="body2">
                        Mark if price is negotiable (optional)
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Box align="right" sx={{ mt: 4 }}>
                  <Button
                    variant="contained"
                    sx={{
                      width: "200px",
                      borderRadius: "50px",
                      height: "50px",
                    }}
                    disabled={
                      !category ||
                      !productOrigin ||
                      !title ||
                      !description ||
                      !state ||
                      !city ||
                      !quantity
                    }
                    onClick={handleEditProduct}
                  >
                    Proceed
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Drawer>

      <Modal
        open={openModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ConfettiExplosion
              duration={6000}
              zIndex={10000}
              particleSize={7}
              colors={["#EBDCEF", "#7B408C", "#1F1023", "#FFCD29", "#FFF9E5"]}
            />
            <img src={successIcon} width={80} />
            <Typography
              sx={{
                mt: 2,
                fontWeight: 700,
                color: "#151515",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              Product updated successfully
            </Typography>
            <Typography
              sx={{
                mt: 1,
                textAlign: "center",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 400,
              }}
            >
              You'll receive an email and in-app notification once it's
              approved.
            </Typography>

            <Button
              variant="contained"
              onClick={() => {
                handleClose();
                onClose();
              }}
              sx={{
                width: "150px",
                height: "50px",
                borderRadius: "50px",
                mt: 3,
              }}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default EditProduct;
