import { CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Switch,
  TextField,
  Typography,
  Modal,
  Autocomplete,
  InputAdornment,
  LinearProgress,
} from "@mui/material";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import successIcon from "../../assets/Icons/sell/5.svg";

import React, { act, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ImImages } from "react-icons/im";
import { HiViewfinderCircle } from "react-icons/hi2";
import { RiDeleteBin2Line } from "react-icons/ri";
import ConfettiExplosion from "react-confetti-explosion";
import { GET_CATEGORIES_N_SUB, GET_PRODUCT_ORIGIN } from "../../Utils/Queries";
import { GET_COUNTRIES, GET_STATES, GET_CITY } from "../../Utils/Queries";
import UploadComponent from "../Products/UploadComponent";
import { CREATE_PRODUCT } from "../../Utils/Mutations";
import Loader from "../../Components/Common/Loader";
import { useSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const EditProduct = ({ open, onClose }) => {

      const { enqueueSnackbar } = useSnackbar();

  const country_id = "65fe9e4dd5a2f2d3f3021a0b"
  const [activeStep, setActiveStep] = useState(1);

  const [uploadedImages, setUploadedImages] = useState(null);

  const [openModal, setOpenModal] = React.useState(false);

  const [isExploding, setIsExploding] = React.useState(false);

  const [productDetails, setProductDetails] = useState({
    category: "",
    title: "",
    description: "",
    state:"",
    city:"",
    price: "",
    quantity: "",
    negotiable: false,
  });

  const updateUploadedImages = (images) => {
    setUploadedImages(images);
  };

      const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const [selectedPreviewImage, setSelectedPreviewImage] = useState(null);

  useEffect(() => {
    if (uploadedImages) {
      setSelectedPreviewImage(uploadedImages[0]);
    }
  }, [uploadedImages]);

  const handleOpen = () => {
    setIsExploding(true);
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
    setIsExploding(false);
  };
    useEffect(() => {

    getState({
      variables: {
        country: country_id,
      }
    })
         getCity({
           variables: {
             state: productDetails?.state?._id,
           },
         });
  }, [productDetails?.state]);



  const { loading: loadingCategories, data: categories, error } = useQuery(
    GET_CATEGORIES_N_SUB
  );
  const { loading: loadingOrigins, data: origins } = useQuery(
    GET_PRODUCT_ORIGIN
  );

    const [getCity, { loading: cityLoad, error: cityError, data: cityData }] =
    useLazyQuery(GET_CITY);

    const [getState, { loading: stateLoad, error: statError, data: stateData }] =
    useLazyQuery(GET_STATES);

  const [productOrigin, setProductOrigin] = useState(null);

  const optionsList = origins?.getProductOrigins?.items || [];
  // console.log(optionsList)


  const {category,title,description,state,city,price, quantity, negotiable} = productDetails;

  const [isLoading, setIsLoading] = useState(false)

  const [createProduct] = useMutation(CREATE_PRODUCT);

  const handleCreateProduct = async () => {
    setIsLoading(true)


    try {
      // const formData = new FormData();
   
  //  for (let i = 0; i < uploadedImages.length; i++) {
  //       formData.append("files", uploadedImages[i]);
  //     }
      const {data} = await createProduct({
        variables:{
files:uploadedImages,
payload:{
  category:category?._id,
  name:title,
  description:description,
  price:parseFloat(price),
  isNegotiable:negotiable,
  weight:20,
  quantityInStock:parseFloat(quantity),
  country:productOrigin?._id,
  productState:state?._id
}

        }
      })
      handleOpen()

             setIsLoading(false)
    } catch (error) {
                setIsLoading(false)
      console.error("Error submitting dispute:", error);
         handleAlert(`${error.message}`, "error")
    }
  }


  return (
    <>
      <Drawer open={open} onClose={onClose} anchor="right">
      {
      isLoading && <Loader/>
      }
        <Box sx={{  width: {lg: "50vw", md:'50vw', sm:'80vw', xs:'95vw'}, p: 5, boxSizing: "border-box" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ fontWeight: 600, fontSize: "20px", color: "#151515" }}
            >
              {activeStep === 1 ? "Edit Service" : "Review Product"}
            </Typography>
            <IconButton onClick={onClose} sx={{ color: "#151515" }}>
              <CloseOutlined />
            </IconButton>
          </Box>

          {activeStep === 1 && (
            <>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" sx={{ fontSize: "14px" }}>
                  Upload Images
                </Typography>
                <Typography sx={{ mt: 0.4 }}>
                  First picture-is the title picture. Drag to order <br />
                  (maximum of 4 photos, Pictures may not exceed <b>5MB</b>)
                </Typography>
              </Box>

              <Box sx={{ mt: 2 }}>
                <UploadComponent updateImages={updateUploadedImages}  initialImages={uploadedImages}/>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="filled"
                        label="Category"
                        fullWidth
                        value={productDetails.category}
                        onChange={(e) =>
                          setProductDetails({
                            ...productDetails,
                            category: e.target.value,
                          })
                        }
                        size="large"
                        margin="dense"
                        select
                      >
                        {categories?.fetchAllCategoriesAndTheirSubCategories?.map(
                          (cat, index) => (
                            <MenuItem
                              key={index}
                              value={cat?.category}
                              sx={{
                                color: "#151515",
                                fontWeight: 400,
                                bgcolor: "#fff",
                              }}
                            >
                              {cat?.category?.name}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </Box>
                  </Grid>
                  <Grid item md={6}  sm={12} xs={12}>
                    <Box>
                      <Autocomplete
                        value={productOrigin}
                        onChange={(event, newValue) => {
                          setProductOrigin(newValue);
                       
                        }}
                        variant="filled"
                        options={origins?.getProductOrigins?.items}
                        getOptionLabel={(option) => option.name}
                        fullWidth
                        loading={loadingOrigins}
                        size="large"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            label=" Product Origin"
                         
                            margin="dense"
                            placeholder="Select country"
                            
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={12}  sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="filled"
                        label="Title"
                        fullWidth
                        margin="dense"
                        value={productDetails.title}
                        onChange={(e) =>
                          setProductDetails({
                            ...productDetails,
                            title: e.target.value,
                          })
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item md={12}  sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="filled"
                        label="Description"
                        fullWidth
                        multiline
                        rows={4}
                        margin="dense"
                        value={productDetails.description}
                        onChange={(e) =>
                          setProductDetails({
                            ...productDetails,
                            description: e.target.value,
                          })
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item md={6}  sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="filled"
                        label="State"
                        fullWidth
                        size="large"
                        margin="dense"
                        select
                        value={productDetails.state}

                        onChange={(e)=>setProductDetails({...productDetails, state:e.target.value

                        })}
                       
                      >
                        {
                          stateData?.fetchStates?.items?.map((item)=>(

                            <MenuItem value={item}  sx={{
                                color: "#151515",
                                fontWeight: 400,
                                bgcolor: "#fff",
                              }}>{item?.name}</MenuItem>
                          ))
                        }
                      </TextField>
                    </Box>
                  </Grid>{" "}
                  <Grid item md={6}  sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="filled"
                        label="City"
                        fullWidth
                        size="large"
                        margin="dense"
                           value={productDetails.city}
                        onChange={(e)=>setProductDetails({...productDetails, city:e.target.value

                        })}
                        select
                     
                      >
                        {
                          cityLoad ? (
                            <LinearProgress/>
                          ):(
                            
                            
                          cityData?.fetchCities?.items?.map((item)=>(

                            <MenuItem value={item}  sx={{
                                color: "#151515",
                                fontWeight: 400,
                                bgcolor: "#fff",
                              }}>{item?.name}</MenuItem>
                          ))
                        
                          
                          )
                        }
                      
                      </TextField>
                    </Box>
                  </Grid>
                  <Grid item md={6}  sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="filled"
                        label="Price"
                        fullWidth
                        size="large"
                        type="number"
                        margin="dense"
                        value={productDetails.price}
                        onChange={(e) =>
                          setProductDetails({
                            ...productDetails,
                            price: e.target.value,
                          })
                        }
                        InputProps={{
                             inputMode:'numeric', pattern:'[0-9]*',
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={6}  sm={12} xs={12}>
                    <Box>
                      <TextField
                        variant="filled"
                        label="Stock Quantity"
                        placeholder="Stock Quantity"
                        fullWidth
                        size="large"
                        margin="dense"
                        type="number"
                        value={productDetails.quantity}
                        onChange={(e) =>
                          setProductDetails({
                            ...productDetails,
                            quantity: e.target.value,
                          })
                        }
                           inputMode='numeric'
                           pattern='[0-9]*'
                      />
                    </Box>
                  </Grid>
                  <Grid item md={12}  sm={12} xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <Switch
                        checked={productDetails.negotiable}
                        onChange={(e) =>
                          setProductDetails({
                            ...productDetails,
                            negotiable: e.target.checked,
                          })
                        }
                      />
                      <Typography variant="body2">
                        Mark if price is negotiable
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Box align="right" sx={{ mt: 4 }}>
                  <Button
                    variant="contained"
                    sx={{
                      width: "200px",
                      borderRadius: "50px",
                      height: "50px",
                    }}
                    disabled={!category || !productOrigin || !title || !description || !state || !city || !quantity || !uploadedImages}
                    onClick={() => setActiveStep(2)}
                  >
                    Proceed
                  </Button>
                </Box>
              </Box>
            </>
          )}
          {activeStep === 2 && (
            <>
              <Box sx={{ mt: 3 }}>
                <Box sx={{ height: "380px", display: "flex", columnGap: 2 }}>
                  <Box
                    className="hide_scrollbar"
                    sx={{
                      width: "75px",
                      height: "375px",
                      overflowY: "scroll",

                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {uploadedImages?.map((image, index) => (
                      <Box
                        key={index}
                        onClick={() => setSelectedPreviewImage(image)}
                        sx={{
                          background: `url('${image?.preview}')`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          borderRadius: "5px",
                          height: "70px",
                          mb: 1.5,
                          width: "100%",
                          transition: "0.2s all linear",
                          flexShrink: 0,
                          ...(selectedPreviewImage === image && {
                            background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),url('${image?.preview}')`,
                          }),
                        }}
                      />
                    ))}
                  </Box>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Box
                    sx={{
                      flex: 1,
                      background: `url('${selectedPreviewImage?.preview}')`,
                      backgroundSize: "contain",
                      backgroundRepeat:'no-repeat',
                      backgroundPosition: "center",
                      borderRadius: "5px",
                      transition: "0.1s all linear",
                      border:'0.5px solid #dedede'
                    }}
                  ></Box>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Typography sx={{ fontSize: "12px", color: "#4D4D4D" }}>
                    Product Origin: <b> {productOrigin?.name}</b> 
                  </Typography>

                  <Typography variant="subtitle1" sx={{ fontSize: "25px" }}>
                 {title}
                  </Typography>
                  <Box
                    sx={{
                      mt: 1,
                      display: "flex",
                      alignItems: "center",
                      columnGap: 2,
                    }}
                  >
                    <Typography sx={{}} variant="subtitle1">
                      ${parseFloat(price).toLocaleString()}
                    </Typography>
                    <Chip
                      label={negotiable ? "Negotiable" : "Buy now"}
                      sx={{
                        bgcolor: "#E9FCF9",
                        color: "#148B77",
                        fontWeight: 500,
                      }}
                    />
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography>{`${city?.name}, ${state?.name}`}</Typography>
                  </Box>

                  <Box sx={{ mt: 3 }}>
                    <Typography variant="subtitle1">Description</Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                {description}
                    </Typography>
                  </Box>

                  <Divider sx={{ mt: 5 }} />
                  <Box
                    sx={{
                      mt: 3,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      onClick={() => setActiveStep(1)}
                      variant=""
                      sx={{
                        bgcolor: "#F3F3F3",
                        width: "40%",
                        borderRadius: "50px",
                        height: "50px",
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        width: "40%",
                        borderRadius: "50px",
                        height: "50px",
                      }}
                      onClick={handleCreateProduct}
                    >
                      Submit Product
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Drawer>

      <Modal
        open={openModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ConfettiExplosion
              duration={6000}
              zIndex={10000}
              particleSize={7}
              colors={["#EBDCEF", "#7B408C", "#1F1023", "#FFCD29", "#FFF9E5"]}
            />
            <img src={successIcon} width={80} />
            <Typography
              sx={{
                mt: 2,
                fontWeight: 700,
                color: "#151515",
                fontSize: "18px",
                textAlign: "center",
              }}
            >
              Product Submitted
            </Typography>
            <Typography
              sx={{
                mt: 1,
                textAlign: "center",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 400,
              }}
            >
              You'll receive an email and in-app notification once it's
              approved.
            </Typography>

            <Button
              variant="contained"
              onClick={()=>{
                handleClose()
              onClose()
              }}
              sx={{
                width: "150px",
                height: "50px",
                borderRadius: "50px",
                mt: 3,
              }}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default EditProduct;


