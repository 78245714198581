import {
  Box,
  Button,
  InputAdornment,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Grid,
  TableContainer,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { RiSearch2Line } from "react-icons/ri";
import EmptyData from "../Components/Common/EmptyData";
import AddProducts from "./Products/AddProducts";
import AddService from "./Service/AddService";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import Overview from "./Service/Overview";
import { useSnackbar } from "notistack";
import { useQuery } from "@apollo/client";

import { GET_MY_SERVICE } from "../Utils/Queries";
import Loader from "../Components/Common/Loader";

const datas = [
  {
    name: "Apple",
    reference: "34783h3b",
    quantityInStock: 4,
    price: 67,
    status: "Approved",
  },
];

const Service = () => {
  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const [serviceData, setServiceData] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(false);

  const [isOverview, setIsOverView] = useState(false);

  const [filteredService, setFilteredService] = useState(null);

  const { data: service, loading: serLoading, error: serError } = useQuery(
    GET_MY_SERVICE
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePageLeft = (event, newPage) => {
    setPage(page - 1);
  };
  const handleChangePageRight = (event, newPage) => {
    setPage(page + 1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const startRow = page * rowsPerPage + 1;
  const endRow = Math.min(startRow + rowsPerPage - 1, filteredService?.length);
  const rowsText = `${startRow}-${endRow} of ${filteredService?.length}`;

  const [OverviewData, setOverviewData] = useState(null);

  const handleViewDetails = (service) => {
    setIsOverView(true);
    setOverviewData(service);
  };

  useEffect(() => {
    setServiceData(service?.getMyServices?.items);
  }, [service]);

  useEffect(() => {
    setFilteredService(serviceData);
  }, [serviceData, service]);

  const [searchParams, setSearchParams] = useState("");

  const handleFilter = (filters) => {
    if (filters === "all") {
      setFilteredService(serviceData);
    } else {
      const filtered = serviceData?.filter(
        (item) => item?.status?.toLowerCase() === filters
      );

      setFilteredService(filtered);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;

    setSearchParams(value);
    const filtered = serviceData?.filter((item) =>
      item?.title.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredService(filtered);
  };

  if (serError) {
    handleAlert(`${serError.message}`, "error");
  }


  return (
    <>
      {serLoading && <Loader />}
      {isOverview ? (
        <>
          <Overview  handleBack={()=>setIsOverView(false)} data={OverviewData}/>
        </>
      ) : (
        <>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography variant="subtitle1" sx={{fontSize:{lg:'16px', md:'16px', sm:'14px', xs:'14px'}}}>My Services</Typography>
                <Typography variant="body1">
                  Monitor all services here
                </Typography>
              </Box>
              <Button
                variant="contained"
                 sx={{ borderRadius: "50px", px: 2, fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'10px'} }}
                onClick={() => setOpenDrawer(true)}
              >
                Add Services
              </Button>
            </Box>
            <Box
              sx={{
                mt: 3,
                bgcolor: "#F7F6F6",
                borderRadius: "8px",
                boxSizing: "border-box",
                p: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", columnGap: 2 }}
                >
                  <Typography>Filter:</Typography>
                  <TextField
                    select
                    size="small"
                    sx={{ width: "120px", bgcolor: "#fff" }}
                  >
                    <MenuItem
                      onClick={() => handleFilter("all")}
                      value="All"
                      sx={{ fontWeight: 400, fontSize: "12px" }}
                    >
                      All
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleFilter("approved")}
                      value="Approved"
                      sx={{ fontWeight: 400, fontSize: "12px" }}
                    >
                      Approved
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleFilter("pending")}
                      value="Pending"
                      sx={{ fontWeight: 400, fontSize: "12px" }}
                    >
                      Pending
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleFilter("declined")}
                      value="Declined"
                      sx={{ fontWeight: 400, fontSize: "12px" }}
                    >
                      Declined
                    </MenuItem>
                  </TextField>
                </Box>
                <TextField
                  value={searchParams}
                  onChange={handleSearch}
                  sx={{ width: "35%", bgcolor: "#fff" }}
                  size="small"
                  placeholder="Search Products"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiSearch2Line />
                      </InputAdornment>
                    ),
                    style: {
                      bgcolor: "#fff",
                    },
                  }}
                />
              </Box>

              <Box
                sx={{
                  mt: 2,
                  bgcolor: "#fff",
                  border: "1px solid #E4E4E7",
                  borderRadius: "8px",
                  boxSizing: "border-box",
                  p: 0.5,
                }}
              >
                {!filteredService || filteredService?.length === 0 ? (
                  <>
                    <Box
                      sx={{
                        height: "400px",
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <EmptyData
                        title="You have no product yet"
                        label={"This is where you’ll see products."}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                <TableContainer>
                      <Table>
                      <TableHead sx={{ bgcolor: "#FAFAFA" }}>
                        <TableRow>
                          <TableCell     sx={{
                                 display: {
                                      lg: "table-cell",
                                      md: "table-cell",
                                      sm: "none",
                                      xs: "none",
                                    },
                              }}>ID</TableCell>
                          <TableCell>Title</TableCell>
                          <TableCell>Price</TableCell>

                          <TableCell align="center">Status</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredService
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          ?.map((service, index) => (
                            <TableRow key={index}>
                              <TableCell sx={{ fontSize: "10px",              display: {
                                      lg: "table-cell",
                                      md: "table-cell",
                                      sm: "none",
                                      xs: "none",
                                    }, }}>
                                {service?.reference}
                              </TableCell>
                              <TableCell sx={{ fontSize: "10px",       whiteSpace: "nowrap", }}>
                                {service?.title}
                              </TableCell>
                              <TableCell sx={{ fontSize: "10px",       whiteSpace: "nowrap", }}>
                                ${parseFloat(service?.price)}
                              </TableCell>

                              <TableCell
                                align="center"
                                sx={{ fontSize: "10px",       whiteSpace: "nowrap", }}
                              >
                                {service?.status}
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  onClick={() => handleViewDetails(service)}
                                  sx={{
                                    border: "1px solid #E4E4E7",
                                    fontSize: "10px",
                                          whiteSpace: "nowrap",
                                  }}
                                >
                                  View Details
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                </TableContainer>
                    <Grid
                      container
                      sx={{ bgcolor: "#fff", mt: 3, px: 3, py: 1 }}
                    >
                      <Grid
                        item
                        lg={4}
                        md={4}
                        xs={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          sx={{
                            color: "#424242",
                            fontSize: "13px",
                          }}
                        >
                          {rowsText}
                        </Typography>
                      </Grid>
                      <Grid item lg={8} md={8} xs={8}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Select
                            size="small"
                            sx={{
                              color: "#424242",
                              fontSize: "13px",
                            }}
                            value={rowsPerPage}
                            onChange={handleChangeRowsPerPage}
                            InputProps={{
                              style: {
                                fontSize: "13px",
                                // borderRadius: "8px",

                                // Replace with your desired font family
                              },
                            }}
                          >
                            <MenuItem sx={{ color: "#424242" }} value={5}>
                              5
                            </MenuItem>
                            <MenuItem sx={{ color: "#424242" }} value={10}>
                              10
                            </MenuItem>
                            <MenuItem sx={{ color: "#424242" }} value={25}>
                              25
                            </MenuItem>
                            <MenuItem sx={{ color: "#424242" }} value={50}>
                              50
                            </MenuItem>
                          </Select>
                          <Box
                            sx={{
                              borderLeft: "1px solid #424242",
                              pl: { md: 4, xs: 1 },
                              ml: { md: 3, xs: 1 },
                            }}
                          >
                            <IconButton
                              disabled={startRow === 1}
                              onClick={handleChangePageLeft}
                              sx={{
                                width: "30px",
                                height: "30px",
                                border: "1px solid #424242",
                                "&.Mui-disabled": {
                                  border: "1px solid #4242421a",
                                },
                              }}
                            >
                              <ChevronLeftRoundedIcon />
                            </IconButton>
                            <IconButton
                              disabled={endRow === filteredService?.length}
                              onClick={handleChangePageRight}
                              sx={{
                                ml: 4,
                                width: "30px",
                                height: "30px",
                                border: "1px solid #424242",
                                "&.Mui-disabled": {
                                  border: "1px solid #4242421a",
                                },
                              }}
                            >
                              <ChevronRightRoundedIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}

      <AddService
        open={openDrawer}
        onClose={() => setOpenDrawer(!openDrawer)}
      />
    </>
  );
};

export default Service;
