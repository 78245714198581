// import { store }  from "./store";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { gql } from "@apollo/client";
import client from "../Utils/ApolloInstance";
import { Navigate } from "react-router-dom";



const GET_PRODUCTS = gql`
  query {
    listAllAvailableProductsWithoutAuthentication(
      query: {}
      pageOptions: { page: 1, limit: 15 }
    ) {
      items {
        _id
        reference
        name
        description
        alias
        price
        createdAt
        merchant {
          firstName
          lastName
          phone
        }
        country{
          flagUrl
         name
        }
        currency {
          name
          symbol
          shortCode
        }
        isInStock
        isNegotiable
        quantityInStock
        images
        status
      }
    }
  }
`;

const GET_PRODUCTS_AUTH = gql`
  query {
    getMarketPlaceProductListing(
      query: {}
      pageOptions: { page: 1, limit: 15 }
    ) {
      items {
        _id
        reference
        name
        description
        alias
        price
        merchant {
          firstName
          lastName
          phone
        }
        country {
          flagUrl
         name
        }
        currency {
          name
          symbol
          shortCode
        }
        isInStock
        isNegotiable
        quantityInStock
        images
        status
      }
      pagination
    }
  }
`;


export const getProducts = createAsyncThunk("products/get", async () => {

  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  try {
    const response = await client.query({
      query:  isAuthenticated ? GET_PRODUCTS_AUTH : GET_PRODUCTS,
    });
    return isAuthenticated
      ?  response.data?.getMarketPlaceProductListing.items :response.data?.listAllAvailableProductsWithoutAuthentication.items;
  } catch (error) {
//     console.error("Error fetching products:", error.message, typeof(error.message));
//     if(isAuthenticated && error.message === "Invalid Bearer token."){
// // Navigate("/login")
//     }
    throw error;
  }
});

const productSlice = createSlice({
  name: "products",
  initialState: {
    data: [],
    status: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = "idle";
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.status = "error";
      });
  },
});

export default productSlice.reducer;
