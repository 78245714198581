import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import BlogCard from "../Cards/BlogCard";
import blogImg from "../../assets/newton.jpeg";
import { Link } from "react-router-dom";

const newsData = [
  {
    img: blogImg,
    title: "I discovered why at 33",
    caption:
      "I embraced Software Engineering shortly after I woke up from a hunger-induced nap (another day's story!) with an idea to build an online platform that would change the course of social. ",
  },
  {
    img: blogImg,
    title: "I discovered why at 33",
    caption:
      "I embraced Software Engineering shortly after I woke up from a hunger-induced nap (another day's story!) with an idea to build an online platform that would change the course of social. ",
  },
  {
    img: blogImg,
    title: "I discovered why at 33",
    caption:
      "I embraced Software Engineering shortly after I woke up from a hunger-induced nap (another day's story!) with an idea to build an online platform that would change the course of social. ",
  },
  {
    img: blogImg,
    title: "I discovered why at 33",
    caption:
      "I embraced Software Engineering shortly after I woke up from a hunger-induced nap (another day's story!) with an idea to build an online platform that would change the course of social. ",
  },
];

const NewsBlog = () => {
  return (
    <>
      <Box sx={{ py: 2, px: { lg: 0, md: 0, sm: 2, xs: 2 } }}>
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: { lg: "24px", xs: "20px", md: "24px", sm: "20px" },
            fontWeight: 700,
          }}
        >
          News Updates
        </Typography>
        <Grid container spacing={4} sx={{ mt: 0 }}>
          {newsData.map(({ img, title, caption }, index) => (
            <Grid item lg={3} md={3} sm={6} xs={12} key={index}>
              <Link to={`/blog/${index}`}>
                <BlogCard image={img} title={title} caption={caption} link={`/blog/${index}`} />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default NewsBlog;
