import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  ListItemButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Avatar,
  TextField,
  CircularProgress,
  InputLabel,
  Skeleton,
  Modal,
  useTheme,
  useMediaQuery,
  InputAdornment,
} from "@mui/material";
import { GrFormEdit } from "react-icons/gr";
import React, { useEffect, useState } from "react";
import { IoIosEye } from "react-icons/io";
import { IoIosEyeOff } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { MdArrowRightAlt } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";
import { VscLocation } from "react-icons/vsc";
import {
  GET_ALL_PUSRSES,
  GET_MY_APPOINTMENT,
  GET_MY_ORDERS,
  GET_MY_PRODUCTS,
  GET_MY_SERVICE,
  GET_PROFILE,
} from "../Utils/Queries";
import { useSnackbar } from "notistack";
import { formatDistanceToNow } from "date-fns";

import EmptyData from "../Components/Common/EmptyData";
import AddAccount from "./Dashboard/AddAccount";
import GetPaid from "./Dashboard/GetPaid";



const Dashboard = () => {
  const [hide, setHide] = useState(true);



  const location = useLocation();

  const navigate = useNavigate();



  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const { loading, data: wallet, error } = useQuery(GET_ALL_PUSRSES);
  const { data, loading: proLoaing, error: proError } = useQuery(
    GET_MY_PRODUCTS
  );
  const { data: service, loading: serLoading, error: serError } = useQuery(
    GET_MY_SERVICE
  );

  const { data: orders, loading: orderLoading, error: orderError } = useQuery(
    GET_MY_ORDERS
  );

  const { data: appointments, loading: apLoading, error: apError } = useQuery(
    GET_MY_APPOINTMENT
  );

  const storeBalance = wallet?.getWallets[0]?.earningBalance;

  const productCount = data?.fetchMerchantProducts?.items?.length || "00";

  const serviceCount = service?.getMyServices?.items?.length || "00";
  const orderCount = orders?.getSellerOrders?.items || [];
  const appointmentCount =
    appointments?.fetchSellerAppointments?.items?.length || "00";

  if (error) {
    handleAlert(`${error.message}`, "error");
  }
  if (proError) {
    handleAlert(`${proError.message}`, "error");
  }
  if (serError) {
    handleAlert(`${serError.message}`, "error");
  }
  if (orderError) {
    handleAlert(`${orderError.message}`, "error");
  }
  if (apError) {
    handleAlert(`${apError.message}`, "error");
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const {
    data: profile,
    error: profileErrror,
    loading: profileLoading,
  } = useQuery(GET_PROFILE);

  const { firstName, lastName, addresses, imageUrl } =
    profile?.getProfile?.profile || [];

  const [readOnly, setReadOnly] = useState(true);

  const [defaultAddress, setDefaultAddress] = useState([]);

  useEffect(() => {
    if (addresses) {
      const defaultAddress = addresses?.filter(
        (item) => item?.isDefault === true
      );
      setDefaultAddress(defaultAddress);
    }
  }, [addresses]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={8} sm={12} xs={12}>
          {isMobile && (
            <Box
              sx={{
                border: "1px solid #E4E4E7",
                bgcolor: "#fff",
                p: 2,
                borderRadius: "10px",
                mb: 2,
              }}
            >
              <Typography sx={{ color: "#151515", fontWeight: 500 }}>
                Business Profile
              </Typography>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  alignItems: "center",
                  columnGap: 2,
                }}
              >
                <Box align="right">
                  <Avatar sx={{ width: "60px", height: "60px" }}>
                    <img src={imageUrl} width={60} />
                  </Avatar>
                  <IconButton
                    sx={{
                      bgcolor: "#7B408C",
                      width: "30px",
                      height: "30px",
                      mt: -5,
                    }}
                  >
                    <GrFormEdit style={{ color: "#fff" }} />
                  </IconButton>
                </Box>
                <Box sx={{ mt: -3 }}>
                  <Typography sx={{ color: "#151515", fontWeight: 500 }}>
                    {`Hello ${firstName || "--"}`}
                  </Typography>
                  <Typography sx={{ fontSize: "12px", lineHeight: "16px" }}>
                    Upload an image that is 132 pixels square or round.
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Typography>Business Name</Typography>
                <TextField
                  margin="dense"
                  size="small"
                  fullWidth
                  value={` ${firstName || "--"} ${lastName || "--"}`}
                  InputProps={{
                    style: {
                      borderRadius: "5px",
                      fontWeight: 400,
                    },
                    endAdornment: (
                      <InputAdornment>
                   
                          <IconButton onClick={()=>navigate("/account", {
                      state: { redirectTo: location.pathname },
                    })}>
                            <Typography
                              sx={{ color: "#151515", fontWeight: 600 }}
                            >
                              Edit
                            </Typography>
                          </IconButton>
         
                      </InputAdornment>
                    ),
                    readOnly: readOnly,
                  }}
                />
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  alignItems: "center",
                  columnGap: 2,
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                    width: "80%",
                  }}
                >
                  <VscLocation />
                  <InputLabel>
                    {defaultAddress[0]?.addressOne || "--"}
                  </InputLabel>
                </Box>

                <Typography
                  onClick={() =>
                    navigate("/account/address", {
                      state: { redirectTo: location.pathname },
                    })
                  }
                  sx={{
                    color: "#151515",
                    textDecoration: "underline",
                    fontWeight: 600,
                    mr: 2.5,
                  }}
                >
                  Edit
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  mb: 1,
                  display: "flex",
                  alignItems: "center",
                  columnGap: 2,
                }}
              >
                <Typography sx={{ fontWeight: 600, color: "#151515" }}>
                  Status:
                </Typography>
                <Typography
                  sx={{
                    width: "fit-content",
                    py: 0.5,
                    color: "#FFBD16",
                    bgcolor: "#FFF8E5",
                    fontSize: "10px",
                    px: 2,
                    fontWeight: 500,
                    borderRadius: "6px",
                  }}
                >
                  Verification Pending
                </Typography>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              border: "1px solid #E4E4E7",
              borderRadius: "8px",
              height: "120px",
              p: 3,
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography sx={{ color: "#151515", fontWeight: 600 }}>
                Store Balance
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: 3 }}>
                {hide ? (
                  <Typography
                    sx={{
                      textShadow: "0 0 8px rgba(0, 0, 0, 0.8)",
                      color: "transparent",
                      fontSize: "25px",
                      fontWeight: 700,
                    }}
                  >
                    $XX.XX
                  </Typography>
                ) : loading ? (
                  <CircularProgress size={20} sx={{ mt: 1 }} />
                ) : (
                  <Typography
                    sx={{ fontSize: "25px", color: "#151515", fontWeight: 700 }}
                  >
                    ${parseFloat(storeBalance).toLocaleString()}
                  </Typography>
                )}

                <IconButton onClick={() => setHide(!hide)}>
                  {hide ? (
                    <IoIosEye style={{ fontSize: "15px" }} />
                  ) : (
                    <IoIosEyeOff style={{ fontSize: "15px" }} />
                  )}
                </IconButton>
              </Box>
            </Box>
    <GetPaid storeBalance={storeBalance}/>
          </Box>
<AddAccount/>
          <Box sx={{ mt: 3 }}>
            <Typography sx={{ fontWeight: 500, color: "#151515" }}>
              Overview
            </Typography>

            <Table
              sx={{ border: "1px solid #e4e4e7", borderRadius: "10px", mt: 1 }}
            >
              <TableBody sx={{ borderRadius: "10px" }}>
                <TableRow>
                  <TableCell sx={{ borderRight: "1px solid #e4e4e7" }}>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ color: "#151515" }}>
                          My Products
                        </Typography>
                        <Link to="/sell-on-mamaket?tab=Product">
                          <IconButton>
                            <MdArrowRightAlt />
                          </IconButton>
                        </Link>
                      </Box>
                      {proLoaing ? (
                        <CircularProgress size={20} sx={{ mt: 1 }} />
                      ) : (
                        <Typography
                          sx={{
                            fontWeight: 700,
                            color: "#151515",
                            fontSize: "20px",
                          }}
                        >
                          {productCount}
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ color: "#151515" }}>
                          My Services
                        </Typography>
                        <Link to="/sell-on-mamaket?tab=Service">
                          <IconButton>
                            <MdArrowRightAlt />
                          </IconButton>
                        </Link>
                      </Box>
                      {serLoading ? (
                        <CircularProgress size={20} sx={{ mt: 1 }} />
                      ) : (
                        <Typography
                          sx={{
                            fontWeight: 700,
                            color: "#151515",
                            fontSize: "20px",
                          }}
                        >
                          {serviceCount}
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ borderRight: "1px solid #e4e4e7" }}>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ color: "#151515" }}>
                          My Orders
                        </Typography>
                        <Link to="/sell-on-mamaket?tab=Orders">
                          <IconButton>
                            <MdArrowRightAlt />
                          </IconButton>
                        </Link>
                      </Box>
                      {orderLoading ? (
                        <CircularProgress size={20} sx={{ mt: 1 }} />
                      ) : (
                        <Typography
                          sx={{
                            fontWeight: 700,
                            color: "#151515",
                            fontSize: "20px",
                          }}
                        >
                          {orderCount?.length}
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ color: "#151515" }}>
                          My Appointments
                        </Typography>
                        <Link to="/sell-on-mamaket?tab=Orders&type=appointment">
                          <IconButton>
                            <MdArrowRightAlt />
                          </IconButton>
                        </Link>
                      </Box>
                      {apLoading ? (
                        <CircularProgress size={20} sx={{ mt: 1 }} />
                      ) : (
                        <Typography
                          sx={{
                            fontWeight: 700,
                            color: "#151515",
                            fontSize: "20px",
                          }}
                        >
                          {appointmentCount}
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>

          {/* <Box sx={{ mt: 3 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontWeight: 500, color: "#151515" }}>
                Products
              </Typography>
              <Link to="">
                <Typography
                  sx={{ fontWeight: 500, fontSize: "12px", color: "#151515" }}
                >
                  See All
                </Typography>
              </Link>
            </Box>
            <Box
              sx={{
                border: "1px solid #E4E4E7",
                borderRadius: "8px",
                height: "120px",
                p: 0,
                boxSizing: "border-box",
                px: 2,
                py: 1,
                mt: 1,
              }}
            >
              <ListItemButton>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography variant="body2">Active listings</Typography>
                  <Typography variant="body2">5</Typography>
                </Box>
              </ListItemButton>
              <Divider />
              <ListItemButton>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography variant="body2">Active listings</Typography>
                  <Typography variant="body2">5</Typography>
                </Box>
              </ListItemButton>
            </Box>
          </Box> */}
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <Box sx={{ boxSizing: "border-box" }}>
            {!isMobile && (
              <Box
                sx={{
                  border: "1px solid #E4E4E7",
                  bgcolor: "#fff",
                  p: 2,
                  borderRadius: "10px",
                }}
              >
                <Typography sx={{ color: "#151515", fontWeight: 500 }}>
                  Business Profile
                </Typography>
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                  }}
                >
                  <Box align="right">
                    <Avatar sx={{ width: "60px", height: "60px" }}>
                      <img src={imageUrl} width={60} />
                    </Avatar>
                    <IconButton
                      sx={{
                        bgcolor: "#7B408C",
                        width: "30px",
                        height: "30px",
                        mt: -5,
                      }}
                    >
                      <GrFormEdit style={{ color: "#fff" }} />
                    </IconButton>
                  </Box>
                  <Box sx={{ mt: -3 }}>
                    <Typography sx={{ color: "#151515", fontWeight: 500 }}>
                      {`Hello ${firstName || "--"}`}
                    </Typography>
                    <Typography sx={{ fontSize: "12px", lineHeight: "16px" }}>
                      Upload an image that is 132 pixels square or round.
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography>Business Name</Typography>
                  <TextField
                    margin="dense"
                    size="small"
                    fullWidth
                    value={`${firstName || "--"} ${lastName || "--"}`}
                    InputProps={{
                      style: {
                        borderRadius: "5px",
                        fontWeight: 400,
                      },
                      endAdornment: (
                        <InputAdornment>
                                 <IconButton onClick={()=>navigate("/account", {
                      state: { redirectTo: location.pathname },
                    })}>
                              <Typography
                                sx={{ color: "#151515", fontWeight: 600 }}
                              >
                                Edit
                              </Typography>
                            </IconButton>
                   
                        </InputAdornment>
                      ),
                      readOnly: readOnly,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: 2,
                      width: "80%",
                    }}
                  >
                    <VscLocation />
                    <InputLabel>
                      {defaultAddress[0]?.addressOne || "--"}{" "}
                    </InputLabel>
                  </Box>
                
                    <Typography
                        onClick={() =>
                    navigate("/account/address", {
                      state: { redirectTo: location.pathname },
                    })
                  }
                      sx={{
                        color: "#151515",
                        textDecoration: "underline",
                        fontWeight: 600,
                        mr: 2.5,
                      }}
                    >
                      Edit
                    </Typography>
            
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                  }}
                >
                  <Typography sx={{ fontWeight: 600, color: "#151515" }}>
                    Status:
                  </Typography>
                  <Typography
                    sx={{
                      width: "fit-content",
                      py: 0.5,
                      color: "#FFBD16",
                      bgcolor: "#FFF8E5",
                      fontSize: "10px",
                      px: 2,
                      fontWeight: 500,
                      borderRadius: "6px",
                    }}
                  >
                    Verification Pending
                  </Typography>
                </Box>
              </Box>
            )}

            <Box sx={{ mt: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: 500, color: "#151515" }}>
                  Recent Orders
                </Typography>
                <Link to="/sell-on-mamaket?tab=Orders">
                  <Typography
                    sx={{
                      fontWeight: 400,
                      color: "#151515",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  >
                    See All
                  </Typography>
                </Link>
              </Box>

              <Box
                sx={{
                  mt: 1,
                  bgcolor: "#fff",

                  borderRadius: "8px",
                }}
              >
                {orderLoading ? (
                  <>
                    <Skeleton variant="rectangle" height={40} sx={{ mb: 1 }} />
                    <Skeleton variant="rectangle" height={40} sx={{ mb: 1 }} />
                    <Skeleton variant="rectangle" height={40} sx={{ mb: 1 }} />
                    <Skeleton variant="rectangle" height={40} sx={{ mb: 1 }} />
                  </>
                ) : (
                  <>
                    {(orderCount?.length === 0 || !orderCount) && (
                      <Box
                        sx={{
                          height: "300px",
                          display: "grid",
                          placeContent: "center",
                        }}
                      >
                        <EmptyData title="You have no order yet" />
                      </Box>
                    )}
                    {orderCount?.slice(0, 4)?.map((order, index) => (
                      <>
                        <Link
                          key={index}
                          to={`/sell-on-mamaket?tab=Orders&order_id=${order?._id}`}
                        >
                          <ListItemButton>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: 2,
                              }}
                            >
                              <Avatar sx={{ objectFit: "contain" }}>
                                {order?.buyer?.imageUrl ? (
                                  <img
                                    src={order?.buyer.imageUrl}
                                    width={"100%"}
                                  />
                                ) : (
                                  order?.buyer?.firstName?.charAt(0)
                                )}
                              </Avatar>
                              <Box>
                                <InputLabel
                                  sx={{
                                    fontSize: "12px",
                                    color: "#151515",
                                    fontWeight: 500,
                                  }}
                                >
                                  {`${order?.buyer?.firstName}`} purchased your
                                  product
                                </InputLabel>
                                <Typography
                                  sx={{ fontSize: "10px", lineHeight: "14px" }}
                                >
                                  {order?.createdAt &&
                                    formatDistanceToNow(
                                      new Date(order?.createdAt),
                                      {
                                        addSuffix: true,
                                      }
                                    )}
                                </Typography>
                              </Box>
                            </Box>
                          </ListItemButton>
                        </Link>
                        <Divider />
                      </>
                    ))}
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>


    </>
  );
};

export default Dashboard;
