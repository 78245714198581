import React from "react";
import { motion } from "framer-motion";

// Router Hook
import { useRoutes } from "react-router-dom";

// Auth
import Login from "./Pages/Auth/Login";
import Register from "./Pages/Auth/Register";
import Verify from "./Pages/Auth/Verify";
import CreatePin from "./Pages/Auth/CreatePin";
import ForgetPassword from "./Pages/Auth/ForgetPassword";

// Pages
import Home from "./Pages/Screens/Home";
import Download from "./Pages/Screens/Download";
import ProductView from "./Pages/Screens/ProductView";
import CategoryView from "./Pages/Screens/CategoryView";
import Cart from "./Components/Cart/Cart";
import Wishlist from "./Pages/Screens/Wishlist";
import NoPage from "./Pages/Screens/NoPage";
import RegisterMain from "./Pages/Auth/RegisterMain";
import Blogs from "./FooterContent/Blog/Blogs";

// Dashboard
import {
  Profile,
  SharedLayouts,
  Notification,
  Security,
  Address,
  Mamapurse,
  HelpCenter,
  MyOrders,
} from "./Account-Profile/Pages";
import OrderList from "./Account-Profile/Pages/MyOrder-Component/OrderList";
import OrderDetails from "./Account-Profile/Pages/MyOrder-Component/OrderDetails";
import ServiceView from "./Pages/Screens/ServiceView";
import MyAccount from "./Account-Profile/Layouts/MyAccount";

// Footer Pages
import {
  About,
  Ads,
  Contact,
  Faq,
  Mamapurse as MamapurseFooter,
  Payment,
  PrivacyPolicy,
  ProductDoc,
  ReturnPolicy,
  TermsConditions,
  Verification,
} from "./FooterContent";

// CHat Screen
import Chat from "./Chat/Chat";
import ChatOutlet from "./Chat/ChatOutlet";
import ChatMobile from "./Chat/ChatMobile";

// Protected ROutes COmponent
import ProtectedRoute from "./Utils/ProtectedRoutes";

// Checkout
import Checkout from "./Components/CheckOut/Checkout";
import Confirmation from "./Account-Profile/Components/Mamapurse/Confirmation";
import ServiceOrderDetails from "./Account-Profile/Pages/MyOrder-Component/ServiceOrderDetails";
import NotificationMobile from "./Components/Common/NotificationMobile";
import SingleBlog from "./Pages/Screens/SingleBlog";
import SellOnMamaket from "./SellOnMamaket/SellOnMamaket";
import SellProtectedRoute from "./Utils/SellProtectedRoutes";

const slideInVariants = {
  initial: { x: "-100vw" },
  animate: { x: 0 },
  exit: { x: "-100vw" },
};

export function Routes() {
  return useRoutes([
    {
      path: "/",
      element: <Home />,
    },

    {
path:'/sell-on-mamaket',
element:<SellProtectedRoute>
  <SellOnMamaket/></SellProtectedRoute>
    },
    {
      path: "/checkout",
      element: <Checkout />,
    },
    {
      path: "/download",
      element: <Download />,
    },
    {
      path: "/product-view/:id",
      element: <ProductView />,
    },
    {
      path: "/service-view/:id",
      element: <ServiceView />,
    },
    {
      path: "/category-view/:name/:id",
      element: <CategoryView />,
    },
    {
      path: "/wishlist",
      element: <Wishlist />,
    },
    {
      path: "/cart",
      element: (
     

          <Cart />
 
      ),
    },
    {
      path: "/blog",
      element: <Blogs />,
    },
        {
      path:'/blog/:id',
      element:<SingleBlog/>
    },
    {
      path: "/chat",
      element: (
        <ProtectedRoute>
          <Chat />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "/chat/:id",
          element: <ChatOutlet />,
        },
      ],
    },
    {
      path: "/chat-mobile/:id",
      element: <ChatMobile />,
    },
    {
      path: "/login",
      element: (
        <motion.div
          variants={slideInVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
        >
          <Login />
        </motion.div>
      ),
    },
    {
      path: "/register",
      element: (
        <motion.div
          variants={slideInVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
        >
          <RegisterMain />
        </motion.div>
      ),
    },
    {
      path: "/register-form",
      element: (
        <motion.div
          variants={slideInVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
        >
          {" "}
          <Register />
        </motion.div>
      ),
    },
    {
      path: "/verify",
      element: (
        <motion.div
          variants={slideInVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
        >
          <Verify />
        </motion.div>
      ),
    },
    {
      path: "/createpin",
      element: (
        <motion.div
          variants={slideInVariants}
          // initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
        >
          {" "}
          <CreatePin />
        </motion.div>
      ),
    },
    {
path:'/notifications',
element:<NotificationMobile/>
    },

    {
      path: "/about-us",
      element: <About />,
    },
    {
      path: "/ads",
      element: <Ads />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/faq",
      element: <Faq />,
    },
    {
      path: "*",
      element: <NoPage />,
    },
    {
      path: "/mamapurse",
      element: <MamapurseFooter />,
    },
    {
      path: "/footer-product",
      element: <ProductDoc />,
    },
    {
      path: "/payment",
      element: <Payment />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/product-policy",
      element: <ProductDoc />,
    },
    {
      path: "/return-policy",
      element: <ReturnPolicy />,
    },
    {
      path: "/terms&conditions",
      element: <TermsConditions />,
    },
    {
      path: "/verification",
      element: <Verification />,
    },

    {
      path: "/my-account",
      element: <MyAccount />,
    },
    {
      path: "/forget_password",
      element: <ForgetPassword />,
    },
    {
      element: (
        <ProtectedRoute>
          {" "}
          <SharedLayouts />
        </ProtectedRoute>
      ),
      children: [
        {
          element: <Profile />,
          path: "/account",
        },
        {
          element: <Notification />,
          path: "/account/notification",
        },
        {
          element: <Security />,
          path: "/account/security",
        },
        {
          element: <Address />,
          path: "/account/address",
        },
        {
          element: <Mamapurse />,
          path: "/account/mamapurse",
        },
            {
      path: "/account/mamapurse/confirmation",
      element: <Mamapurse />,
    },
        {
          element: <HelpCenter />,
          path: "/account/help-center",
        },
        {
          element: <MyOrders />,
          // path: "/account/orders",
          children: [
            {
              element: <OrderList />,
              path: "/account/orders",
            },
            {
              element: <OrderDetails />,
              path: "/account/orders/order-details/product/:id",
            },
            {
              element: <ServiceOrderDetails />,
              path: "//account/orders/order-details/service/:id",
            },
          ],
        },
      ],
    },
  ]);
}
